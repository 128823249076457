import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React from "react";
import { useParams } from "react-router-dom";
import { firebaseApp } from "..";
import { UserType } from "./AdminScreen";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { User } from "firebase/auth";

type Reports = {
  reports: Report[];
};
type Report = {
  otherUserUID: string;
  reportMessage: string;
  timeStamp: string;
};

export const UserReportsScreen = () => {
  const { uid } = useParams();
  const [reports, setReports] = React.useState<Reports>();
  const [user, setUser] = React.useState<UserType>({} as UserType);
  const db = getFirestore(firebaseApp);
  React.useEffect(() => {
    const getReports = async () => {
      const docRef = doc(db, "reports", uid ? uid : "");

      getDoc(docRef).then((data) => {
        setReports(data.data() as Reports);
      });
    };
    getReports();
  }, []);

  React.useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", uid ? uid : ""), (doc) => {
      setUser(doc.data() as UserType);
    });
    return () => unsub();
  }, []);

  React.useEffect(() => {}, []);

  const banUser = async () => {
    const banUserRef = doc(db, "users", uid ? uid : "");

    await updateDoc(banUserRef, {
      isBanned: true,
    });
  };

  const unbanUser = async () => {
    const banUserRef = doc(db, "users", uid ? uid : "");

    await updateDoc(banUserRef, {
      isBanned: false,
    });
  };

  function isObjectEmpty(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false; // If the object has at least one property, it's not empty
      }
    }
    return true; // If no properties are found, the object is empty
  }
  if (reports && !isObjectEmpty(user)) {
    return (
      <>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            alignContent: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TableContainer
            style={{ width: "80%", height: "90%", alignSelf: "center" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Report user UID</TableCell>
                  <TableCell>Time Stamp</TableCell>
                  <TableCell>Report Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.reports.map((report, index) => {
                  return (
                    <TableRow key={report.timeStamp}>
                      <TableCell>{report.otherUserUID}</TableCell>
                      <TableCell>{report.timeStamp}</TableCell>
                      <TableCell>{report.reportMessage}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            onClick={user.isBanned ? unbanUser : banUser}
            style={{ alignSelf: "center", width: "80%", borderRadius: 1000 }}
            variant="contained"
            type="button"
            color="warning"
          >
            {user.isBanned ? "Unban user" : "Ban user"}
          </Button>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
