import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const NotFoundScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        <h1 style={styles.heading}>Oops! Page Not Found</h1>
        <p style={styles.description}>
          It seems like the page you're looking for doesn't exist.
        </p>
        <Link to={"/"}>
          <Button style={{ backgroundColor: "#7851a9" }} variant="contained">
            Go back
          </Button>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  image: {
    maxWidth: "100%",
    maxHeight: "300px",
  },

  heading: {
    fontSize: "2em",
    color: "#333",
  },
  description: {
    fontSize: "1.2em",
    color: "#666",
  },
};
