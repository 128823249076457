import React from "react";
import { Instagram, Menu } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";

import CopyrightIcon from "@mui/icons-material/Copyright";

import {
  NavHeader,
  NavItems,
  NavLink,
} from "../styledComponents/styledNavComponents";
import "../styles/navComponentsStyles.css";
import { NavMenu } from "./Menu";
import { SocialIcon } from "react-social-icons";

export const Header = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = React.useState<string>(location.pathname);

  const [open, setOpen] = React.useState<boolean>(false);

  const [matches, setMatches] = React.useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  React.useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <>
      {matches ? (
        <>
          <NavHeader>
            <Link
              style={{
                textDecoration: "none",
                textTransform: "lowercase",
                marginLeft: "5%",
                width: "auto",
                height: "auto",
                display: "flex",
                flexDirection: "row",
              }}
              to="/"
            >
              <p
                style={{
                  color: "#121212",
                  fontFamily: "Roboto-Bold",
                  fontWeight: "bold",
                  fontSize: "36px",
                  textTransform: "capitalize",
                  marginLeft: "4%",
                }}
              >
                Face2Face
              </p>
            </Link>
            <NavItems>
              <div
                onMouseLeave={() => {
                  setActiveLink(location.pathname);
                }}
                onMouseEnter={() => {
                  setActiveLink("/support");
                }}
                style={{
                  marginTop: "5%",
                  paddingLeft: 30,
                  paddingRight: 30,
                  display: "flex",
                  flexDirection: "column",
                  width: "auto",
                  height: "100%",
                }}
              >
                <NavLink to={"/support"}>Support</NavLink>
                {activeLink === "/support" && <div className="dot"></div>}
              </div>

              <div
                onMouseLeave={() => {
                  setActiveLink(location.pathname);
                }}
                onMouseEnter={() => {
                  setActiveLink("/testimonials");
                }}
                style={{
                  marginTop: "5%",
                  display: "flex",
                  flexDirection: "column",
                  width: "auto",
                  height: "100%",
                }}
              >
                <NavLink to={"/testimonials"}>Testimonials</NavLink>
                {activeLink === "/testimonials" && <div className="dot"></div>}
              </div>
            </NavItems>
          </NavHeader>
        </>
      ) : (
        <>
          {!open ? (
            // Render the content when open is true
            <div
              style={{
                width: "100vw",
                height: "7vh",
                position: "fixed",
                display: "flex",
                top: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "8vh",
                  backgroundColor: "#7851a9",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Menu
                    onClick={() => setOpen(!open)}
                    sx={{
                      fontSize: "36px",
                      marginBottom: "auto",
                      marginTop: "auto",
                      marginLeft: "2%",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Roboto-Bold",
                      fontWeight: "bold",
                      fontSize: "28px",
                      alignSelf: "center",
                      marginTop: "auto",
                      textTransform: "capitalize",
                      marginBottom: "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    Face2Face
                  </p>
                  <p
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/us/app/face2facedates/id1661199157"
                      );
                    }}
                    style={{
                      marginRight: "2%",
                      fontFamily: "Roboto-Bold",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    Download
                  </p>
                </div>
              </div>
            </div>
          ) : (
            // Render the content when open is false
            <NavMenu open={open} setOpen={setOpen} />
          )}
        </>
      )}
    </>
  );
};

export const Footer = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          position: "fixed",
          bottom: 0,
          height: "8%",

          width: "100%",
          backgroundColor: "#121212",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            bottom: 5,
          }}
        >
          <CopyrightIcon
            fontSize={"inherit"}
            style={{
              color: "#FAF9F6",
              marginLeft: "2%",
              marginRight: 5,
              marginTop: 11,
            }}
          />
          <p
            style={{
              marginLeft: "1%",
              fontSize: 12,
              fontFamily: "Roboto",
              color: "#FAF9F6",
            }}
          >
            2023 Face2Face Inc.
          </p>

          <SocialIcon
            bgColor="#121212"
            url="https://www.instagram.com/face2.facedates/"
          />

          <SocialIcon
            bgColor="#121212"
            style={{
              fontSize: 12,
              marginLeft: "2%",
              backgroundColor: "transparent",
            }}
            url="https://www.tiktok.com/@face2facedates"
          />
          <div
            style={{
              alignSelf: "center",
              marginLeft: "auto",
              display: "flex",
            }}
          >
            <Link
              style={{
                fontSize: 12,

                color: "#FAF9F6",
                fontFamily: "Roboto",
                textDecoration: "none",
                marginRight: 20,
              }}
              to="/terms"
            >
              Terms
            </Link>
            <Link
              style={{
                fontSize: 12,
                marginRight: 20,
                color: "#FAF9F6",
                fontFamily: "Roboto",
                textDecoration: "none",
              }}
              to="/privacy"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
