import { Button, TextField } from "@mui/material";
import React from "react";
import { login } from "../functions/login";
import { useNavigate } from "react-router-dom";

export const LoginScreen = () => {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const nav = useNavigate();
  const loginUserFlow = async () => {
    const results = await login(email, password);

    if (results) {
      nav("/admin");
    }
  };

  return (
    <>
      <div
        style={{
          width: "109vw",
          height: "100vh",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",

          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "50%",
            height: "40%",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#7851a9",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            required
            type="email"
            label={email.length ? "" : "Email"}
            value={email}
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#121212",
                },
              "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                color: "#121212",
              },
            }}
            onChange={(event) => setEmail(event.target.value)}
            style={{
              alignSelf: "center",
              width: "50%",
              marginTop: "3%",
              marginBottom: "3%",
            }}
            placeholder="Email"
          />
          <TextField
            type="password"
            required
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#121212",
                },
              "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
                color: "#121212",
              },
            }}
            label={password.length ? "" : "Password"}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            style={{
              alignSelf: "center",
              width: "50%",
              marginBottom: "3%",
            }}
            color="info"
            placeholder="Password"
          />

          <Button
            onClick={() => {
              loginUserFlow();
            }}
            sx={{
              backgroundColor: "#121212",
              color: "white",
              "&:hover": {
                backgroundColor: "#121212",
                color: "white",
              },
            }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};
