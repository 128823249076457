import React from "react";
import { Link } from "react-router-dom";

export const TermsAndConditions = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10%",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            marginBottom: "15%",
            width: "80%",
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <h2>Welcome to Face2Face Dating App</h2>
          <p>
            Before using our app, please read and agree to the following Terms
            and Conditions. By accessing or using the Face2Face app, you signify
            your acceptance of these terms and conditions. If you do not agree
            with any part of these terms, please refrain from using the app.
          </p>

          <h3>1. Age Restriction:</h3>
          <p>
            Face2Face is strictly intended for use by individuals who are 18
            years of age or older. By using the app, you confirm that you are at
            least 18 years old. Any person below the age of 18 is strictly
            prohibited from using this app.
          </p>

          <h3>2. User Conduct:</h3>
          <p>
            You agree to use the Face2Face app responsibly and respectfully,
            adhering to all applicable laws and regulations. You must not engage
            in any harmful, offensive, or inappropriate behavior while using the
            app, including but not limited to harassment, stalking, or sharing
            explicit content. Any illegal or unauthorized use of the app will
            result in termination of your account and may lead to legal action.
          </p>

          <h3>3. Account Registration:</h3>
          <p>
            To use the Face2Face app, you must create a valid account. You agree
            to provide accurate and up-to-date information during the
            registration process. You are solely responsible for maintaining the
            confidentiality of your account credentials, and you agree not to
            share your account details with others. You must promptly notify us
            if you suspect any unauthorized access to your account.
          </p>

          <h3>4. User Content:</h3>
          <p>
            By using the Face2Face app, you grant us the right to use any
            content you upload or share on the app, including your profile
            information and media. You retain ownership of your content but
            allow us a worldwide, royalty-free, non-exclusive license to use,
            reproduce, modify, adapt, publish, translate, and distribute your
            content. You are responsible for any content you post on the app and
            must ensure that it does not violate the rights of others or any
            applicable laws.
          </p>

          <h3>5. Privacy Policy:</h3>
          <p>
            Your privacy is important to us. Please refer to our{" "}
            <Link to={"/privacy"}>Privacy Policy</Link> for details on how we
            collect, use, and protect your personal information.
          </p>

          <h3>6. Termination of Account:</h3>
          <p>
            We reserve the right to terminate or suspend your account at any
            time, with or without cause, at our sole discretion. You may also
            delete your account at any time through the app settings.
          </p>

          <h3>7. Modification of Terms:</h3>
          <p>
            We may update or modify these Terms and Conditions from time to
            time. Any changes will be effective immediately upon posting, and
            your continued use of the app after the changes will signify your
            acceptance of the updated Terms and Conditions.
          </p>

          <h3>8. Intellectual Property:</h3>
          <p>
            All content and materials on the Face2Face app, including but not
            limited to graphics, logos, design elements, and software, are
            protected by intellectual property rights and belong to Face2Face or
            its licensors.
          </p>

          <h3>9. Limitation of Liability:</h3>
          <p>
            NEITHER US NOR ANY OWNER WILL BE LIABLE FOR ANY DAMAGES, DIRECT,
            INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE,
            INCLUDING, WITHOUT LIMITATION, LOSS OF DATA, INCOME, PROFIT OR
            GOODWILL, LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES
            ARISING OUT OF YOUR ACCESS TO OR USE OF THE APP, SITE, OUR CONTENT,
            OR ANY MEMBER CONTENT, HOWEVER CAUSED, WHETHER BASED ON BREACH OF
            CONTRACT, TORT (INCLUDING NEGLIGENCE), PROPRIETARY RIGHTS
            INFRINGEMENT, PRODUCT LIABILITY OR OTHERWISE.
          </p>
          <p>
            THE FOREGOING SHALL APPLY EVEN IF WE WERE ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES. IF YOU BECOME DISSATISFIED IN ANY WAY WITH THE APP
            OR SITE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP YOUR USE OF THE
            APP AND SITE.
          </p>
          <p>
            YOU HEREBY WAIVE ANY AND ALL CLAIMS ARISING OUT OF YOUR USE OF THE
            APP OR SITE. BECAUSE SOME STATES DO NOT ALLOW THE DISCLAIMER OF
            IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN TYPES
            OF DAMAGES, THESE PROVISIONS MAY NOT APPLY TO YOU. IF ANY PORTION OF
            THIS LIMITATION ON LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE
            FOR ANY REASON, THEN OUR AGGREGATE LIABILITY SHALL NOT EXCEED ONE
            HUNDRED DOLLARS ($100).
          </p>
          <p>
            THE LIMITATION OF LIABILITY HEREIN IS A FUNDAMENTAL ELEMENT OF THE
            BASIS OF THE BARGAIN AND REFLECTS A FAIR ALLOCATION OF RISK. THE APP
            AND SITE WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS AND YOU
            AGREE THAT THE LIMITATIONS AND EXCLUSIONS OF LIABILITY, DISCLAIMERS
            AND EXCLUSIVE REMEDIES SPECIFIED HEREIN WILL SURVIVE EVEN IF FOUND
            TO HAVE FAILED IN THEIR ESSENTIAL PURPOSE. THE FOREGOING DOES NOT
            APPLY TO LIABILITY ARISING FROM ANY FRAUD OR FRAUDULENT
            MISREPRESENTATIONS, OR ANY OTHER LIABILITY THAT CANNOT BE LIMITED BY
            APPLICABLE LAW.
          </p>

          <h3>10. Indemnity:</h3>
          <p>
            All the actions you make and information you post on Face2Face
            remain your responsibility. Therefore, you agree to indemnify,
            defend, release, and hold us, and our partners, licensors,
            affiliates, contractors, officers, directors, employees,
            representatives and agents, harmless, from and against any third
            party claims, damages (actual and/or consequential), actions,
            proceedings, demands, losses, liabilities, costs and expenses
            (including reasonable legal fees) suffered or reasonably incurred by
            us arising as a result of, or in connection with:
          </p>
          <ol style={{ flexDirection: "column" }}>
            <li>any negligent acts, omissions or willful misconduct by you;</li>
            <li>your access to and use of the App;</li>
            <li>the uploading or submission of Content to the App by you;</li>
            <li>any breach of these Terms by you; and/or</li>
            <li>
              your violation of any law or of any rights of any third party
            </li>
          </ol>
          <p>
            We retain the exclusive right to settle, compromise and pay any and
            all claims or causes of action which are brought against us without
            your prior consent. If we ask, you will co-operate fully and
            reasonably as required by us in the defense of any relevant claim.
          </p>
          <p>
            The foregoing provision does not require you to indemnify Face2Face
            for any unconscionable commercial practice or any fraud, deception,
            false promise, misrepresentation or concealment, suppression or
            omission of any material fact in connection with the App.
          </p>

          <h3>11. Termination and Remedies:</h3>
          <p>
            These Terms commence on the date you accept them (as described in
            the preamble) and continue until terminated in accordance with the
            terms herein.
          </p>
          <p>
            You can delete your Account at any time by logging into the App,
            going to the “Account” tab (the person icon bottom right), then
            clicking the gear icon "cog icon" and following the instructions to
            terminate your account.
          </p>
          <p>
            In the event that Face2Face determines, in its sole discretion, that
            you have breached any portion of these Terms, have misused the App,
            or have otherwise demonstrated conduct which the Face2Face regards
            as inappropriate or unlawful (whether on or off the App), Face2Face
            reserves the right to: (a) warn you via email (to any email
            addresses you have provided to Face2Face) that you have violated the
            Terms; (b) delete your User Content; (c) discontinue your Account;
            (d) discontinue your subscription(s) without refund; (e) notify
            and/or send your User Content to and/or fully cooperate with the
            proper law enforcement authorities for further action; and/or (f)
            pursue any other action which Face2Face deems to be appropriate. You
            agree that all terminations for cause shall be made in Face2Face’s
            sole discretion and that Face2Face shall not be liable to you or any
            third party for any termination of your Account.
          </p>
          <p>
            Termination of these Terms or your Account includes the removal of
            access to your Account, and all related information and content
            associated with or inside your Account.
          </p>
          <p>
            If your account is terminated by you or by Face2Face for any reason,
            all provisions of these Terms which by their nature should survive,
            shall survive termination of these Terms, including, without
            limitation, the Arbitration Agreement, ownership provisions,
            warranty disclaimers and limitation of liability. Your information
            will be maintained and deleted in accordance with our{" "}
            <Link to={"/privacy"}>Privacy Policy</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
