import React from "react";

export const HomeScreen = () => {
  const [matches, setMatches] = React.useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  const screenHeight = window.innerHeight;

  React.useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  if (matches) {
    return (
      <>
        <div
          style={{
            marginTop: "8%",
            flexDirection: "column",
            display: "flex",
            height: "100%",
            width: "100%",
            marginBottom: "10%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "80%",
              alignContent: "center",
            }}
          >
            <div
              style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(images/Face2FaceBack.png)`,
                height: screenHeight,
                width: "100%",
                marginBottom: "2%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "10em",
                  fontFamily: "Roboto-Bold",
                  alignSelf: "center",
                  justifySelf: "center",
                }}
              >
                Face2Face
              </h1>
            </div>
          </div>

          <div
            style={{
              height: "auto",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              width: "90%",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                fontFamily: "Roboto",
              }}
            >
              Face2Face not your typical dating app
            </h2>
            <div>
              <h3 style={{ fontFamily: "Roboto" }}>Our Unique Approach </h3>
              <p style={{ fontFamily: "Roboto" }}>
                Here at Face2Face, we're breaking away from the norms of
                traditional dating apps. We require our matches to engage in a
                video call before jumping into text messaging. This unique
                approach is designed to reduce anxiety when meeting in person
                for the first time and to let your true personality shine
                through.
              </p>
            </div>

            <div>
              <h3 style={{ fontFamily: "Roboto" }}>
                Video Call Your Match Before Messaging
              </h3>
              <p style={{ fontFamily: "Roboto" }}>
                With Face2Face, you get the chance to have a video call with
                your match before you even start messaging. This feature brings
                a human touch to online dating, allowing you to see and hear the
                person behind the profile. It's a chance to have a real
                conversation, share your thoughts, and express yourself
                authentically.
              </p>
            </div>
            <div>
              <h3 style={{ fontFamily: "Roboto" }}>Why Video Calls Matter</h3>
              <p style={{ fontFamily: "Roboto" }}>
                Video calls provide a deeper level of connection. You'll get to
                experience your match's laughter, expressions, and
                gestures—things that can't be captured through text alone. Video
                calls enable you to gauge chemistry and compatibility before
                investing time in messaging. It's like meeting someone face to
                face, without the pressure of an in-person meetup.
              </p>
            </div>
            <div>
              <h3 style={{ fontFamily: "Roboto" }}>How It Works</h3>
              <ol style={{ fontFamily: "Roboto" }}>
                <li
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <h4> Discover Matches:</h4>
                  Browse through profiles that match your preferences and
                  interests.
                </li>
                <li style={{ flexDirection: "row" }}>
                  <h4>Initiate a Video Call:</h4>
                  When you find someone intriguing, initiate a video call right
                  from the app.
                </li>
                <li style={{ flexDirection: "row" }}>
                  <h4>Connect Authentically:</h4>
                  Engage in a real-time video conversation and get to know each
                  other better.
                </li>
                <li style={{ flexDirection: "row" }}>
                  <h4> Start Messaging:</h4>
                  If the video call goes well, you'll have the perfect starting
                  point for meaningful messaging.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            marginTop: "8%",
            flexDirection: "column",
            display: "flex",
            height: "100%",
            width: "100%",
            marginBottom: "10%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "80%",
              alignContent: "center",
            }}
          >
            <div
              style={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(images/Face2FaceBack.png)`,
                height: screenHeight,
                width: "100%",
                marginBottom: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "5em",
                  fontFamily: "Roboto-Bold",
                  alignSelf: "center",
                  justifySelf: "center",
                }}
              >
                Face2Face
              </h1>
            </div>
          </div>

          <div
            style={{
              height: "auto",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              width: "90%",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                fontFamily: "Roboto",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              Face2Face not your typical dating app
            </h2>
            <div>
              <h3 style={{ fontFamily: "Roboto" }}>Our Unique Approach </h3>
              <p style={{ fontFamily: "Roboto" }}>
                Here at Face2Face, we're breaking away from the norms of
                traditional dating apps. We require our matches to engage in a
                video call before jumping into text messaging. This unique
                approach is designed to reduce anxiety when meeting in person
                for the first time and to let your true personality shine
                through.
              </p>
            </div>

            <div>
              <h3 style={{ fontFamily: "Roboto" }}>
                Video Call Your Match Before Messaging
              </h3>
              <p style={{ fontFamily: "Roboto" }}>
                With Face2Face, you get the chance to have a video call with
                your match before you even start messaging. This feature brings
                a human touch to online dating, allowing you to see and hear the
                person behind the profile. It's a chance to have a real
                conversation, share your thoughts, and express yourself
                authentically.
              </p>
            </div>
            <div>
              <h3 style={{ fontFamily: "Roboto" }}>Why Video Calls Matter</h3>
              <p style={{ fontFamily: "Roboto" }}>
                Video calls provide a deeper level of connection. You'll get to
                experience your match's laughter, expressions, and
                gestures—things that can't be captured through text alone. Video
                calls enable you to gauge chemistry and compatibility before
                investing time in messaging. It's like meeting someone face to
                face, without the pressure of an in-person meetup.
              </p>
            </div>
            <div>
              <h3 style={{ fontFamily: "Roboto" }}>How It Works</h3>
              <ol>
                <li
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <h4> Discover Matches:</h4>
                  Browse through profiles that match your preferences and
                  interests.
                </li>
                <li style={{ flexDirection: "row" }}>
                  <h4>Initiate a Video Call:</h4>
                  When you find someone intriguing, initiate a video call right
                  from the app.
                </li>
                <li style={{ flexDirection: "row" }}>
                  <h4>Connect Authentically:</h4>
                  Engage in a real-time video conversation and get to know each
                  other better.
                </li>
                <li style={{ flexDirection: "row" }}>
                  <h4> Start Messaging:</h4>
                  If the video call goes well, you'll have the perfect starting
                  point for meaningful messaging.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </>
    );
  }
};
