import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

type NavMenuProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavMenu = ({ open, setOpen }: NavMenuProps) => {
  return (
    <>
      <div
        style={{
          zIndex: 2,
          width: "100%",
          height: "100%",
          display: "flex",
          position: "fixed",
          top: 0,
          backgroundColor: "#7851a9",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <img
            style={{
              height: "50px",
              width: "75px",
              marginLeft: "3%",
              marginTop: "2%",
              marginBottom: "auto",
            }}
            alt="face2face logo"
            src={"images/Face2FaceICON.png"}
          />

          <IconButton
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginTop: "1.5%",
              marginBottom: "auto",
              marginRight: "5%",
            }}
          >
            <Close
              onClick={() => setOpen(!open)}
              sx={{ fontSize: "36px", marginBottom: "auto", marginTop: "auto" }}
            />
          </IconButton>
        </div>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <NavLink
            onClick={() => setOpen(false)}
            style={{
              marginTop: "3%",
              fontFamily: "Roboto",
              fontSize: "24pt",
              marginLeft: "2%",
              marginBottom: "3%",
              textDecoration: "none",
              color: "#121212",
            }}
            to={"/"}
          >
            Home
          </NavLink>
          <NavLink
            onClick={() => setOpen(false)}
            style={{
              fontFamily: "Roboto",
              fontSize: "24pt",
              marginLeft: "2%",
              marginBottom: "3%",
              textDecoration: "none",
              color: "#121212",
            }}
            to={"/support"}
          >
            Support
          </NavLink>
          <NavLink
            onClick={() => setOpen(false)}
            style={{
              fontFamily: "Roboto",
              fontSize: "24pt",
              marginLeft: "2%",
              marginBottom: "3%",
              textDecoration: "none",
              color: "#121212",
            }}
            to={"/testimonials"}
          >
            Testimonials
          </NavLink>
        </div>
      </div>
    </>
  );
};
